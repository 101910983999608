import { Warnings } from 'components'
import { Header } from 'components/new'
import React from 'react'
import styled from 'styled-components'

import Footer from './Footer'

const Main = styled.main`
  height: 100%;
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 800;
`

const PageLayout = ({ children, containerId }) => (
  <Main id={containerId}>
    <Sticky>
      <Warnings />
    </Sticky>
    <Header />
    {children}
    <Footer />
  </Main>
)

export default PageLayout
