import { StyledGoodcoverLogo } from 'components/GoodcoverLogo'
import { MainMenu } from 'components/new'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { devices, size } from 'theme'

const StyledHeader = styled.header`
  padding: ${size(0, 3)};
  height: ${size(8)};
  display: flex;
  align-items: center;

  @media ${devices.small} {
    padding: ${size(3, 6, 0, 6)};
  }

  @media ${devices.small} {
    justify-content: space-between;
    padding: ${size(3, 6, 0, 6)};
    position: relative;
    height: 100%;
  }

  @media ${devices.medium} {
    padding: ${size(4.5, 8, 0, 8)};
  }
`

const StyledLink = styled(GatsbyLink)`
  &:focus {
    outline: none;
  }
`

const Header = () => (
  <StyledHeader>
    <StyledLink to="/" title="Goodcover Logo - Navigate Home">
      <StyledGoodcoverLogo />
    </StyledLink>
    <MainMenu />
  </StyledHeader>
)

export default Header
